import React, { FC } from 'react';
import SEO from 'components/seo';
import Hero from 'components/hero';
import Layout from 'containers/layout/layout';
import Header from 'containers/layout/header';
import Footer from 'containers/layout/footer';
import Filters from 'containers/stories/filters';
import FeaturedStoriesSection, { FeaturedStoriesSectionProps } from 'containers/stories/featured-stories-section';
import AuthorProfilesSection from 'containers/stories/author-profiles';
import StoriesTextArea from 'containers/stories/text-area';
import bgStories from 'assets/images/bg/headers/Stories-Header.png';

interface Props {
  pageContext: {
    hero: {
      title: string;
      button: {
        title: string;
        link: string;
      };
    };
    featuredCategories: FeaturedStoriesSectionProps;
  };
  location: string;
}

const StoriesPageTemplate: FC<Props> = ({ pageContext, location }) => {
  return (
    <Layout location={location}>
      <SEO title="News and Stories" image={bgStories} />
      <Header />
      <main className="site-wrapper-reveal">
        <Hero
          title={pageContext?.hero?.title}
          button={pageContext?.hero?.button}
          hero="stories"
        />
        <Filters />
        <FeaturedStoriesSection {...pageContext.featuredCategories} />
        <AuthorProfilesSection />
        <StoriesTextArea />
      </main>
      <Footer />
    </Layout>
  );
};

export default StoriesPageTemplate;
