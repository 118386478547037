import Blog from 'components/blog';
import Heading from 'components/ui/heading';
import { Col, Container, Row } from 'components/ui/wrapper';
import React, { FC } from 'react';
import { FeaturedStoriesSectionWrapper } from './featured-stories-section.style';
import Pagination from 'components/blog/pagination';

interface FeaturedStory {
  id: string;
  slug: string;
  title: string;
  postedDate: string;
  excerpt: string;
  photo: any;
}

export interface FeaturedStoriesSectionProps {
  title: string;
  featuredStories: FeaturedStory[];
  sectionHeadingStyle?: object;
  storyTitleStyle?: object;
  storySubtitleStyle?: object;
}

const FeaturedStoriesSection: FC<FeaturedStoriesSectionProps> = ({
  title,
  featuredStories,
  sectionHeadingStyle,
}) => {
  const STORIES_PER_PAGE = 6;
  const numberOfPages = Math.ceil(featuredStories.length / STORIES_PER_PAGE);

  return (
    <FeaturedStoriesSectionWrapper className="left-red-slice sm-no-bg-image">
      <Container>
        <>{title && <Heading {...sectionHeadingStyle}>{title}</Heading>}</>
        <Row>
          {featuredStories.slice(0, STORIES_PER_PAGE).map((story) => (
            <Col lg={6} key={`stories-${story.id}`}>
              <Blog content={story} />
            </Col>
          ))}
        </Row>
        <Pagination
          rootPage="/news"
          currentPage={1}
          numberOfPages={numberOfPages}
        />
      </Container>
    </FeaturedStoriesSectionWrapper>
  );
};

FeaturedStoriesSection.defaultProps = {
  sectionHeadingStyle: {
    mb: '50px',
    textalign: 'center',
  },
};

export default FeaturedStoriesSection;
